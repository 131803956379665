import './App.css';

function App() {
    return (
        <div className={'h-screen w-screen bg-cyan-950'}>
            <div className={'border flex items-center justify-center w-full h-full'}>
                <div className="grid grid-cols-2 gap-8">
                    <div>
                        <h1 className="text-4xl font-bold text-gray-100">Nombre Completo</h1>
                        <p className="text-xl text-gray-300">Título Profesional</p>

                        <hr className="my-4" />

                        <h2 className="text-2xl font-bold text-gray-100">Información de Contacto</h2>
                        <ul className="list-disc list-inside text-gray-300">
                            <li>Teléfono: (123) 456-7890</li>
                            <li>Email: ejemplo@example.com</li>
                            <li>Dirección: Ciudad, País</li>
                        </ul>

                        <hr className="my-4" />

                        <h2 className="text-2xl font-bold text-gray-100">Habilidades</h2>
                        <ul className="list-disc list-inside text-gray-300">
                            <li>Habilidad 1</li>
                            <li>Habilidad 2</li>
                            <li>Habilidad 3</li>
                        </ul>
                    </div>

                    <div>
                        <h2 className="text-2xl font-bold text-gray-100">Experiencia Laboral</h2>
                        <div className="mb-8">
                            <h3 className="text-xl font-bold text-gray-700">Cargo 1</h3>
                            <p className="text-gray-300">Empresa - Ciudad, País</p>
                            <p className="text-gray-300">Fecha de inicio - Fecha de finalización</p>
                            <ul className="list-disc list-inside text-gray-300">
                                <li>Logro o responsabilidad</li>
                                <li>Logro o responsabilidad</li>
                                <li>Logro o responsabilidad</li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-gray-700">Cargo 2</h3>
                            <p className="text-gray-300">Empresa - Ciudad, País</p>
                            <p className="text-gray-300">Fecha de inicio - Fecha de finalización</p>
                            <ul className="list-disc list-inside text-gray-300">
                                <li>Logro o responsabilidad</li>
                                <li>Logro o responsabilidad</li>
                                <li>Logro o responsabilidad</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default App;
